<template>
  <div>
    <v-layout align-center>
      <v-autocomplete
        :search-input.sync="search"
        :value="value"
        :items="points"
        clearable
        :loading="isLoading"
        item-text="name"
        :label="label"
        return-object
        @change="emitInput"
        :rules="rules"
        hint="Digite as 3 primeiras letras para buscar"
        :hide-no-data="searched"
      >
        <template v-slot:selection="{ item }">
          <template v-if="fixVuetifyBug(item)">
            <v-avatar class="mr-3 my-2" tile>
              <v-img :src="item.image" />
            </v-avatar>
            <span v-text="item.name"></span>
          </template>
        </template>
        <template v-slot:item="{ item }">
          <template v-if="typeof item == 'object'">
            <v-list-item-avatar tile>
              <v-img :src="item.image" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-btn @click="dialog = true" icon small color="secondary">
        <v-icon>add_circle</v-icon>
      </v-btn>
    </v-layout>
    <v-dialog v-model="dialog" :width="600">
      <form-point
        :point="point"
        @create="created"
        @update="updated"
      ></form-point>
    </v-dialog>
  </div>
</template>

<script>
import { findByName } from "@/services/points-service";
import FormPoint from "@/components/Points/Form";
export default {
  components: {
    "form-point": FormPoint,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    // TRACKING = 0 // IMPACTION = 1
    type: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      headers: [
        { text: "Descrição", value: "Description" },
        { text: "Ações", value: "actions" },
      ],
      selectedPoint: this.value,
      point: {},
      dialog: false,
      search: "",
      points: [],
    };
  },
  created() {
    this.loadValueToItems(this.value);
  },
  computed: {
    searched() {
      return this.search && this.search.length === 0;
    },
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  watch: {
    search(value) {
      if (value && value.length > 0) this.filter(value);
    },
    value(value) {
      this.loadValueToItems(value);
    },
    points(value) {
      if (value === undefined) return [];
    },
  },
  methods: {
    loadValueToItems(value) {
      if (value) {
        this.points = [value];
      }
    },
    emitInput(point) {
      this.$emit("input", point);
    },
    async filter(value) {
      try {
        // TRACKING = 0 // IMPACTION = 1
        let response = await findByName({ name: value, type: this.type });
        this.points = response.data;
      } catch (error) {
        this.$toasted.global.error({ message: "Erro ao filtrar pontos" });
      }
    },
    created(point) {
      this.dialog = false;
      this.points.push(point);
      this.selectedPoint = point;
      this.emitInput(point);
    },
    updated() {
      this.dialog = false;
    },
    fixVuetifyBug(item) {
      if (!item) {
        this.points = [];
      }
      return !!item;
    },
  },
};
</script>

<style></style>
