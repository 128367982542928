import api from "@/services/api-service";
import querystring from "querystring";

const url = "/points";

const find = (query) => {
  return api().post(`${url}/search`, query);
};

const findOne = (id) => {
  return api().get(`${url}/${id}`);
};

const save = (obj) => {
  return api().post(url, obj);
};

const findByName = ({ name, type }) => {
  return api().get(`${url}/name/${name}/type/${type}`);
};

export { find, save, findByName, findOne };
